import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import Button from "@button/Button";

// Assets
import { ReactComponent as BackIcon } from "@icons/back.svg";
import { ReactComponent as CloseIcon } from "@icons/close.svg";
import { ReactComponent as MenuIcon } from "@icons/menu.svg";
import { getClassNames } from "@/utils/helpers";

type HeaderProps = {
  title?: string;
  isHome?: boolean;
  showDemoLink?: boolean;
  showBackButton?: boolean;
};

const Header = ({
  title,
  isHome,
  showDemoLink,
  showBackButton = true,
}: HeaderProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={getClassNames(
        "z-10",
        isHome ? "header-gradient" : "border-b border-b-white2/10"
      )}
    >
      <div className="container-auth flex items-center justify-between py-8">
        {showBackButton ? (
          <BackIcon
            onClick={() => navigate(-1)}
            className="cursor-pointer hover:scale-95"
          />
        ) : (
          <MenuIcon
            onClick={() => navigate("/menu")}
            className="cursor-pointer hover:scale-95"
          />
        )}
        {title && <h1 className="text-[2.5rem] font-medium">{title}</h1>}
        <div className="flex items-center gap-2">
          {isHome && !showDemoLink && (
            <Button
              title="Start"
              onClick={() => navigate("/menu")}
              color=""
              className="text-xl text-white bg-[#6B6B6B80] py-3 hover:scale-95"
            />
          )}
          {/* {!isHome && !showDemoLink && (
            <Button
              title="Contact us"
              onClick={() => navigate("/contact-us")}
              color=""
              className="text-xl text-white bg-[#6B6B6B80] py-3 hover:scale-95"
            />
          )} */}
          {showDemoLink && (
            <Button
              title="Demo Guide"
              onClick={() =>
                (window.location.href =
                  "https://trudenty.notion.site/Overview-of-the-demo-c9493623c2f5463e9019dffa0a26138c?pvs=4")
              }
              color=""
              className="text-xl text-white bg-[#6B6B6B80] py-3 hover:scale-95"
            />
          )}

          {!isHome && (
            <CloseIcon
              className="cursor-pointer hover:scale-95"
              onClick={() => navigate("/")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
