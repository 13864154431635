import React from "react";

import Header from "@components/header";

const ValueProposition = () => {
  return (
    <div className="container-auth mx-auto h-screen flex flex-col">
      <Header title="Product trailer" />
      <div
        style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
        className="mt-7"
      >
        <iframe
          title="value proposition"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
          }}
          src="https://www.youtube.com/embed/md-HL7-icv0"
          allowFullScreen
          className=" rounded-2xl w-full"
        ></iframe>
      </div>
    </div>
  );
};

export default ValueProposition;
