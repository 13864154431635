import { useNavigate } from "react-router-dom";
import Header from "@components/header";
import coverImage from "@images/worldline_ trudenty_cover.png";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-auto h-screen flex flex-col">
      <Header title="" isHome showBackButton={false} />
      <div className="absolute inset-0 z-0 cursor-pointer">
        <img
          src={coverImage}
          className="object-cover w-full h-full cursor-pointer"
          alt={""}
          onClick={() => navigate("/menu")}
        />
      </div>
    </div>
  );
};

export default Home;
//
